<template>
	<div class="compareIcon" :class="types && types.length>1?'':'allowClick'" @click.stop="clickType()">
		对比
		<num v-for="(item,i) in types" class="num" :areaID="areaID" :type="item" :fangAnID="fangAnID" :title="getTypeName(item)"
			@click.stop="clickType(item)">
		</num>
		<el-dialog v-model="isShowDlg" :title="title" width="1400px" destroy-on-close append-to-body>
			<compare :areaID="areaID" :stuID="stuID" :fangAnID="fangAnID" :type="type"></compare>
		</el-dialog>
	</div>	
</template>
<script>
	import compare from './cp_compare.vue'
	import api from './api.js'
	import num from './cp_num.vue'
	export default {
		emits: [],
		components: {
			compare,
			num
		},
		props: {
			types: {
				type: Array,
				default: []
			},
			fangAnID: {
				type: String,
				default: ''
			},
			areaID:{
				type: String,
				default: ''
			},
			stuID:{
				type: String,
				default: ''
			}
		},
		data() {
			return {
				isShowDlg: false,
				list: [],
				type: 0,
				title: ''
			}
		},
		watch: {},
		computed: {},
		created() {},
		methods: {
			clickType(type) {
				if (!this.types || this.types.length == 0)
					return
				if (!type && this.types.length > 1)
					return
				if (!type)
					type = this.types[0]
				this.type = type
				this.title = this.getTypeName(this.type)
				this.isShowDlg = !this.isShowDlg
			},
			getTypeName(type) {
				let val = ''
				switch (type) {
					case 1:
						val = '比较院校'
						break
					case 5:
						val = '比较专业'
						break
				}
				return val
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.compareIcon {
		border: 1px solid #ddd;
		padding: 10px 5px;
		width: fit-content;
		writing-mode: vertical-lr;
		position: fixed;
		right: 2px;
		top: 45%;
		background-color: #409EFF;
		color: white;
		border-radius: 5px;
		cursor: default;
	}

	.allowClick {
		cursor: pointer;
	}

	.num {
		cursor: pointer;
		padding: 3px 0px;
	}

	.num:nth-child(n+2) {
		border-top: 1px solid white;
	}
</style>