<template>
	<div>
		<div v-for="(item,index) in list" class="item">
			<el-icon v-if="item.Level==1" class="icon" size="20" style="color: #E6A23C;" title="警告,请特别注意">
				<Warning />
			</el-icon>
			<el-icon v-else class="icon" size="20" style="color: #67C23A;" title="推荐">
				<CircleCheck />
			</el-icon>
			<div class="content">
				<span class="seq">{{index+1}}.</span>
				<span v-if="!item.FrontContent" v-html="item.Content"></span>
				<template v-else>
					<span>{{item.FrontContent}}</span>
					<el-link @click="moreClick(item)" class="more">更多</el-link>
				</template>
				<span v-if="item.Status<2" class="waiting">待审核</span>
				<!-- <el-icon v-if="item.Status==1" title="待审核" class="waiting">
					<Stamp />
				</el-icon> -->
				<div class="bottom">
					<div class="left">
						<span title="解读人">{{item.AddMngName}}</span>
						<span title="添加日期">{{item.AddDt}}</span>
					</div>
					<div class="right">
						<template v-if="item.IsEditable">
							<el-icon title="修改" class="operateIcon" @click="edit(item)">
								<Edit />
							</el-icon>
							<el-icon title="删除" class="operateIcon" @click="del(item)">
								<Delete />
							</el-icon>
						</template>
						<el-icon @click="copy(item)" class="operateIcon" title="复制解读内容">
							<DocumentCopy />
						</el-icon>
						<el-icon title="上报错误" style="" class="operateIcon" @click="addError(item.ID)">
							<Warning />
						</el-icon>
						<!-- <el-link type="info" @click="addError(item.ID)">上报错误</el-link> -->
						<span class="zan" title="赞数量">
							<img v-if="item.IsZan" src="/imgs/zan_2.png" @click="ZanClick(item)"
								:disabled="isSaveing" />
							<img v-else src="/imgs/zan_1.png" @click="ZanClick(item)" :disabled="isSaveing" />
							<span class="zanNum">{{item.ZanNum}}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="add" @click="add">
			<el-icon size="20" class="addIcon">
				<CirclePlus />
			</el-icon><span>添加解读</span>
		</div>
	</div>
	<el-dialog v-model="isShowEdit" :title="title" width="850px" :close-on-click-modal="true"
		:close-on-press-escape="true" append-to-body>
		<editContent :id="focID" :refType="refType" :refID="refID" @afterSaved="afterSaved"
			@afterCancled="afterCancled">
		</editContent>
	</el-dialog>
	<el-dialog v-model="isShowErr" title="上报错误" width="650px" :close-on-click-modal="true" :close-on-press-escape="true"
		destroy-on-close append-to-body>
		<div class="focContent">
			{{this.focContent}}
		</div>
		<el-input rows="5" type="textarea" v-model="error" placeholder="请对错误进行描述"></el-input>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="isShowErr = false">取消</el-button>
				<el-button type="primary" @click="SubmitErr">
					保存
				</el-button>
			</div>
		</template>
	</el-dialog>
</template>
<script>
	import editContent from "./cp_editContent.vue";
	export default {
		emits: ['closePop', 'afterSaved'],
		components: {
			editContent
		},
		props: {
			refID: {
				Type: String,
				default: ''
			},
			refType: {
				Type: Number,
				default: 0
			},
			list: {
				Type: Array,
				default: []
			}
		},
		data() {
			return {
				isShowEdit: false,
				isShowErr: false,
				focID: 0,
				error: '',
				isSaveing: false,
				title:''
			}
		},
		watch: {},
		computed: {
			focContent() {
				let content = ''
				let mdl = this.getContentMdl(this.focID)
				if (mdl != null)
					content = mdl.Content
				return content
			},
			
		},
		created() {

		},
		methods: {
			addError(id) {
				this.focID = id
				this.isShowErr = true
				this.$emit('closePop')
			},
			SubmitErr() {
				let mdl = {
					ContentID: this.focID,
					Error: this.error,
				}
				this.$http.post('/tag/AddError', mdl, true).then(res => {
					if (res.code == 1) {
						this.error = ''
						this.$message.success('上报错误成功')
					} else {
						this.$message.error(res.msg)
					}
				})
				this.isShowErr = false
			},
			getContentMdl(id) {
				let mdl = this.list.find(c => c.ID == id)
				return mdl
			},
			ZanClick(mdl) {
				if (mdl.IsZan)
					mdl.IsZan = 0
				else
					mdl.IsZan = 1
				this.isSaveing = true
				this.$http.get('/tag/ZanClick', {
					contentID: mdl.ID,
					isAdd: mdl.IsZan
				}, true).then(res => {
					this.isSaveing = false
					if (res.code == 1) {
						if (mdl.IsZan) {
							this.$message.success('已赞')
							mdl.ZanNum++
						} else {
							this.$message.warning('已取消赞')
							mdl.ZanNum--
						}
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			copy(mdl) {
				let _this = this
				navigator.clipboard.writeText(mdl.Content).then(function() {
					_this.$message.success('文本已复制到剪切板')
				}, function(err) {
					_this.$message.error('复制失败')
				});
			},
			moreClick(mdl) {
				mdl.FrontContent = ''
			},
			afterSaved() {
				this.isShowEdit = false
				this.$emit('afterSaved')
			},
			afterCancled() {
				this.isShowEdit = false
			},
			add() {
				this.title='新增解读'
				this.focID =0
				this.isShowEdit = true
				this.$emit('closePop')
			},
			edit(mdl) {
				this.focID = mdl.ID
				this.title='编辑解读'
				this.isShowEdit = true
				this.$emit('closePop')
			},
			del(mdl) {
				this.$confirm("确认要删除吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.get('Tag/DelContent', {
						id: mdl.ID
					}, true).then(res => {
						if (res.code == 1) {
							this.$emit('afterSaved')
						} else {
							this.$message.error(res.msg)
						}
					})
				})
				this.$emit('closePop')
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.item {
		display: flex;
		line-height: 1.6;
		border-bottom: 1px solid #eee;
		margin-bottom: 10px;

		.icon {
			margin: 0px 5px;
		}

		.content {
			font-size: 16px;
			color: #333;
			width: calc(100% - 30px);
		}

		.waiting {
			color: red;
			margin-left: 5px;
			font-size: 12px;
		}

		.bottom {
			font-size: 12px;
			color:#999;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 15px 0px 0px 0px;
			.left {
				margin: 0px;
				>* {
					margin-right: 10px;
				}
			}

			.right {
				margin: 0px;

				>* {
					margin-left: 10px;
				}
			}
		}

		.zan {
			img {
				width: 14px;
				cursor: pointer;
			}

			.zanNum {
				display: inline-block;
				// width: 60px;
				margin-left: 7px;
			}
		}

		.operateIcon {
			cursor: pointer;
		}

		.operateIcon:hover {
			color: #409EFF;
		}

		.seq {
			font-weight: 600;
		}

		.more {
			cursor: pointer;
			color: var(--el-link-hover-text-color);
		}
	}

	.focContent {
		padding: 15px;
		border-radius: 5px;
		border: 1px solid #eee;
		margin: 10px 0px;
	}

	.add {
		display: flex;
		align-items: center;
		position:absolute;
		right:8px;
		top: 13px;
		cursor: pointer;
	}
	.add:hover
	{
		color:#409EFF;
	}

	.addIcon {
		margin-right: 10px;
		margin: 0px 5px;		
	}
</style>