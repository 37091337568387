<template>
	<div>
		<div class="remark">
			<!-- <b>一句话解读</b><br />1.新增：内容变化后，系统会根据关键词，自动提取标签。你也可手动追加标签。<br />2.修改：修改内容后，不会自动提取标签；如需重新组织标签，可点“一键提取标签”进行重置。 -->
			<b>注：</b>自动提取的标签仅供参考，请根据实际情况手工调整。
		</div>
		<el-input v-model="tagContent.Content" :rows="5" type="textarea" placeholder="请粘贴或输入一句话" @keyup="onKeyup"
			:disabled="isDisabled" />
		<div v-if="similars && similars.length>0" class="similars">
			<span>
				已有相似内容（<b>{{this.similars.length}}</b>）
			</span>
			<div v-for="(item,index) in similars">
				<span class="seq">{{index+1}}.</span>
				<span>{{item.Content}}</span>
				<span class="similar-val" title="相似度(0~1.0)">{{item.Similar}}</span>
				<span class="person" title="添加人">{{item.AddMngName}}</span>
			</div>
		</div>
		<div class="item">
			<span>等级：</span>
			<el-radio-group v-model="tagContent.Level">
				<template v-for="item in tagLevels">
					<el-radio-button :label="item.Key">{{item.Value}}</el-radio-button>
				</template>
			</el-radio-group>
		</div>
		<div class="item">
			<span>来源：</span>
			<el-input v-model="tagContent.Src" style="width: 400px;"></el-input>
		</div>
		<div class="optItem">
			<span class="title1">相关标签</span>
			<el-link @click="autoAnalyze">
				<el-icon>
					<Operation />
				</el-icon><span>一键提取标签</span>
			</el-link>
			<el-link @click="isShowDrawer=true">
				<el-icon>
					<CirclePlus />
				</el-icon><span>手动添加</span>
			</el-link>
			<el-link @click="clear" :disabled="tagCount==0" class="clearAll">
				<el-icon>
					<MagicStick />
				</el-icon>
				<span>清空</span>
			</el-link>

		</div>
		<el-table :data="tagContent.Tags" stripe style="width: 100%;" border row-key="MngID"
			@selection-change="selectChange">
			<el-table-column prop="Dic.TypeName" align="center" label="类型" width="120">
				<template #default="scope">
					<span :class="getClassByType(scope.row.Dic.Type)">{{scope.row.Dic.TypeName}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="Dic.Name" align="left" label="名称" width="220">
				<template #default="scope">
					<el-link v-if="scope.row.Dic.Href" :href="scope.row.Dic.Href"
						target="_blank">{{scope.row.Dic.Name}}</el-link>
					<span v-else>{{scope.row.Dic.Name}}</span>
					<el-icon v-if="scope.row.IsChoose" class="selfChoose" title="手动添加的标签">
						<Link />
					</el-icon>
				</template>
			</el-table-column>
			<el-table-column prop="Dic.Alias" align="left" label="别名" />
			<el-table-column align="center" width="70px" label="操作">
				<template #default="scope">
					<el-link @click="delTag(scope.row)" title="删除" class="delLink">
						<el-icon>
							<Remove />
						</el-icon>
					</el-link>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottomBar">
			<div v-loading="loading" style="width:calc(100% - 180px);"></div>
			<el-button @click="cancel">取 消</el-button>
			<el-badge :value="tagCount">
				<el-button :disabled="isDisabled || tagCount==0 || !tagContent.Content" type="primary" @click="Save">保
					存</el-button>
			</el-badge>
		</div>
		<el-drawer v-model="isShowDrawer" title="选择标签" size="900" :append-to-body="true">
			<chooseDic :selIDs="selIDs" @afterAdd="afterAdd" @afterDel="afterDel"></chooseDic>
		</el-drawer>
	</div>
</template>
<script>
	import chooseDic from './cp_chooseDic.vue'
	export default {
		components: {
			chooseDic
		},
		emits: ['afterSaved', 'afterCancled'],
		props: {
			id: {
				type: Number,
				default: 0
			},
			refType: {
				type: Number,
				default: 0
			},
			refID: {
				type: String,
				default: ''
			},
			isAdmin: {
				type: Number,
				default: 0
			},
			isDisabled: {
				type: Boolean,
				default: false
			},
		},
		computed: {
			selIDs() {
				let ary = []
				if (this.tagContent && this.tagContent.Tags) {
					ary = this.tagContent.Tags.map((item) => {
						return item.DicID
					})
				}
				return ary
			},
			tagCount() {
				let num = 0
				if (this.tagContent && this.tagContent.Tags) {
					num = this.tagContent.Tags.length
				}
				return num
			},
			loading() {
				let val = false
				if (this.waitingNum > 0)
					val = true
				else
					val = false
				return val
			}
		},
		data() {
			return {
				tagContent: {},
				tagLevels: [],
				isShowDrawer: false,
				tm: null,
				tm2: null,
				similars: [],
				waitingNum: 0
			}
		},
		watch: {
			'tagContent.Content': function(newVal, oldVal) {
				this.startAutoAnalyze()
				this.startGetSimilarContents()
			},
			id() {
				this.loadContent()
			},
			refType() {
				this.loadContent()
			},
			refID() {
				this.loadContent()
			},
		},
		created() {
			this.getTagLevels()
			this.loadContent()
		},
		methods: {
			loadContent() {
				this.tagContent = {}
				this.similars = []
				this.waitingNum = 0
				this.$http.get('Tag/GetContent?id=' + this.id, {
					refID: this.refID,
					refType: this.refType
				}, true).then(res => {
					if (res.code == 1) {
						this.tagContent = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			getTagLevels() {
				this.$http.get('Tag/GetTagLevels', {}, true).then(res => {
					if (res.code == 1) {
						this.tagLevels = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			getClassByType(type) {
				let val = 'type' + type
				return val
			},
			startAutoAnalyze() {
				if (this.tm) {
					clearTimeout(this.tm)
					this.tm = null
				}
				this.tm = setTimeout(this.autoAnalyze, 2000)
			},
			autoAnalyze() {
				this.waitingNum++
				this.$http.post('Tag/AutoAnalyze', {
					id: this.id,
					content: this.tagContent.Content
				}, false).then(res => {
					this.waitingNum--
					if (res.code == 1) {
						let chooseAry = this.tagContent.Tags.filter(c => c.IsChoose == true)
						res.data.forEach((item) => {
							let mdl = chooseAry.find(c => c.DicID == item.DicID)
							if (mdl == null)
								chooseAry.push(item)
						})
						this.tagContent.Tags = chooseAry
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			startGetSimilarContents() {
				if (this.tm2) {
					clearTimeout(this.tm2)
					this.tm2 = null
				}
				this.tm2 = setTimeout(this.getSimilarContents, 2000)
			},
			getSimilarContents() {
				this.waitingNum++
				this.$http.post('Tag/GetSimilarContents', {
					id: this.id,
					content: this.tagContent.Content
				}, false).then(res => {
					this.waitingNum--
					if (res.code == 1) {
						this.similars = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},

			delTag(tag) {
				// this.$confirm("确认要删除吗?", "警告", {
				// 	confirmButtonText: "确定",
				// 	cancelButtonText: "取消",
				// 	type: "warning",
				// }).then(() => {
				// 	let idx = this.tagContent.Tags.indexOf(tag)
				// 	if (idx > -1)
				// 		this.tagContent.Tags.splice(idx, 1)
				// })
				let idx = this.tagContent.Tags.indexOf(tag)
				if (idx > -1)
					this.tagContent.Tags.splice(idx, 1)
			},
			afterAdd(dic) {
				// debugger
				let mdl = this.tagContent.Tags.find(c => c.DicID == dic.ID)
				if (mdl == null) {
					this.tagContent.Tags.push({
						DicID: dic.ID,
						Dic: dic,
						IsChoose: true
					})
				}
			},
			afterDel(dic) {
				let mdl = this.tagContent.Tags.find(c => c.DicID == dic.ID)
				if (mdl != null) {
					let idx = this.tagContent.Tags.indexOf(mdl)
					this.tagContent.Tags.splice(idx, 1)
				}
			},
			clear() {
				// this.$confirm("确认要清空吗?", "警告", {
				// 	confirmButtonText: "确定",
				// 	cancelButtonText: "取消",
				// 	type: "warning",
				// }).then(() => {
				// 	this.tagContent.Tags = []
				// })
				this.tagContent.Tags = []
			},
			Save() {
				this.$confirm("确认保存吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.post('Tag/Save?isadmin=' + this.isAdmin, this.tagContent, true).then(res => {
						if (res.code == 1) {
							this.$message.success("保存成功")
							this.loadContent()
							this.$emit('afterSaved')
						} else {
							this.$message.error(res.msg)
						}
					})
				})
			},
			cancel() {
				this.$emit('afterCancled')
			},
			onKeyup(e) {
				if (e.keyCode == 13) {
					if (this.tm) {
						clearTimeout(this.tm)
						this.tm = null
					}
					this.autoAnalyze()

					if (this.tm2) {
						clearTimeout(this.tm2)
						this.tm2 = null
					}
					this.getSimilarContents()
				}
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.optItem {
		margin: 10px 0px;
		display: flex;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.bottomBar {
		margin: 15px 0px 0px 0px;
		display: flex;
		justify-content: right;

		>* {
			margin-left: 15px;
			margin-right: 5px;
		}
	}

	.type1 {
		color: #409EFF;
	}

	.type2 {
		color: #67C23A;
	}

	.type3 {
		color: #E6A23C;
	}

	.type4 {
		color: #F56C6C;
	}

	.type5 {
		color: #DB7093;
	}

	.type6 {
		color: #FF00FF;
	}

	.item {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.delLink {
		font-size: 16px;
		color: red;
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	.selfChoose {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	.similars {
		margin: 5px 0px;
		padding: 10px 10px;
		color: red;
		font-size: 12px;
		line-height: 1.8;
	}

	.similar-val {
		color: #409EFF;
		margin: 0px 5px;
	}

	.person {
		color: #409EFF;
	}
</style>