<template>
	<div>
		<div style="margin-bottom: 15px;">
			<el-radio-group v-model="level" @change="changelevel" style="display: inline-block;float: left;">
				<el-radio-button label="本科"></el-radio-button>
				<el-radio-button label="专科"></el-radio-button>
			</el-radio-group>
			<el-button plain type="success" style="margin-left: 10px;float: left;" @click="onExpand">
				{{expandAll?"折叠":"全部展开"}}
			</el-button>
			<!-- 一键设置热门专业 -->
			<el-button v-if="this.level=='本科'" @click="this.isDrawOnKey=true" type="warning"
				style="margin-left: 10px;float: left;">一键设置
			</el-button>
			<!-- <el-button plain size="mini" type="success" style="margin-left: 10px;float: left;height: 40px;"
				@click="onFavOnly">
				{{favOnly?"显示全部":"只显示有倾向"}}
			</el-button> -->
			<br>
			<br>
		</div>
		<div>
			<el-input v-model="keyWord" placeholder="请输入专业关键字" class="input-with-select" @keyup="keyUp">
				<template #append>
					<el-button icon="Search" @click="search()" />
				</template>
			</el-input>
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="所有专业" name="所有专业">
				<el-tree ref="tree1" :filter-node-method="filterNode" :data="majortree" node-key="ID"
					:expand-on-click-node="false" :props="defaultProps" :default-expand-all="expandAll">
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span>
								<el-link title="点击查看专业" :href="'#/majorSecond?code='+data.MajorCode" target="_blank"
									v-if="data.LayerNum==3" :class="!data.IsInherit&&data.PID!=''?'color4':''">
									{{ node.label }}
								</el-link>
								<span v-else>{{ node.label }}</span>

								<span class="color3" v-if="data.HasFavChild">（{{data.FavChildCount}}）</span>
								<el-rate :max="3" v-model="data.FavValue" @change="majorchange(data)">
								</el-rate>

								<el-icon class="el-icon-s-release" :class="data.FavValue==-1?'color3':''"
									style="float: left;" :disabled="data.FavValue==-1"
									@click="data.FavValue=-1;majorchange(data);" title="设为不考虑">
									<Failed />
								</el-icon>

								<el-icon class="el-icon-s-release" style="float: left;"
									@click="data.FavValue=0;majorchange(data);" title="清空设置">
									<Refresh />
								</el-icon>

								<!-- <el-link class="to_jieshao" title="点击查看专业介绍">介绍</el-link> -->
							</span>
						</span>
					</template>
				</el-tree>
			</el-tab-pane>
			<el-tab-pane label="喜欢的专业" name="喜欢的专业">
				<el-table :data="majorfavs" style="width: 100%">
					<el-table-column label="专业" width="250" :formatter="handle1">
					</el-table-column>
					<el-table-column label="极喜欢 ⭐⭐⭐️">
						<template #default="scope">
							<div v-if="scope.row.Value==3">
								<!-- <span class="seq">{{scope.row.Seq}}</span>
								<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
									@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
								<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
										class="el-icon-bottom"></i></el-button> -->
								<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
									<Check />
								</el-icon>
							</div>
							<div v-else>
								<el-icon :size="18" class="caca" @click="majorchange2(scope.row,3);">
									<Check />
								</el-icon>
							</div>
						</template>
					</el-table-column>
					<el-table-column #default="scope" label="很喜欢 ⭐⭐">
						<div v-if="scope.row.Value==2">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="majorchange2(scope.row,2);">
								<Check />
							</el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="喜欢 ⭐">
						<div v-if="scope.row.Value==1">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="majorchange2(scope.row,1);">
								<Check />
							</el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18">
								<Refresh />
							</el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="不喜欢的专业" name="不喜欢的专业">
				<el-table :data="majornothinks" style="width: 100%">
					<el-table-column prop="FullName" label="专业" width="350" />
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18">
								<Refresh />
							</el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<el-dialog v-model="dialogVisible" title="一键设置">
			<div class="demo-collapse">

				<el-checkbox-group v-model="wlChecked" size="large">
					<el-checkbox-button v-for="item in wlTypes" :key="item.key" :label="item.key">
						{{ item.name }}
					</el-checkbox-button>
				</el-checkbox-group>

				<el-collapse accordion v-model="activeHot" @change="expandHot">
					<template v-for="item in hotMajors">
						<el-collapse-item v-if="isShowGroup(item)" :title="item.Name" :name="item.ID">
							<div style="padding-bottom: 30px;">
								<span style="float: left;">批量设置：</span>
								<el-rate :max="3" v-model="item.FavValue" @change="groupChanged(item)">
								</el-rate>
								<el-icon class="el-icon-s-release" :class="item.FavValue==-1?'color3':''"
									style="float: left;" :disabled="item.FavValue==-1"
									@click="item.FavValue=-1;groupChanged(item);" title="设为不考虑">
									<Failed />
								</el-icon>

								<el-icon class="el-icon-s-release" style="float: left;" :disabled="item.FavValue==0"
									@click="item.FavValue=0;groupChanged(item);" title="清空设置">
									<Refresh />
								</el-icon>

							</div>
							<div v-if="item.childList!=undefined" style="clear: both;">
								<template v-for="child in item.childList">
									<span v-if="isShowMajor(child)"
										style="display: inline-block;padding: 5px 20px 5px 10px;margin-right: 5px;margin-bottom: 5px;border: 1px solid #DCDFE6;position: relative;">
										<span>{{child.Name}}</span>
										<span v-if="child.JobIndex" title="专业景气度"
											style="color: #E6A23C;">☀️{{child.JobIndex}}</span>
										<el-rate :max="3" v-model="child.FavValue" @change="saveMajorFavs2(child)">
										</el-rate>
										<el-icon class="el-icon-s-release" :class="child.FavValue==-1?'color3':''"
											style="float: left;" :disabled="child.FavValue==-1"
											@click="child.FavValue=-1;saveMajorFavs2(child);" title="设为不考虑">
											<Failed />
										</el-icon>

										<el-icon class="el-icon-s-release" style="float: left;"
											:disabled="child.FavValue==0"
											@click="child.FavValue=0;saveMajorFavs2(child);" title="清空设置">
											<Refresh />
										</el-icon>

										<span v-if="child.WLType=='114'" class="wltype" style="background-color:red;"
											title="偏理科专业">理</span>
										<span v-else class="wltype" style="background-color:#409EFF;"
											title="偏文科专业">文</span>
									</span>
								</template>
							</div>
						</el-collapse-item>
					</template>
				</el-collapse>
			</div>
			<!-- <template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitHot">保存</el-button>
				</span>
			</template> -->
		</el-dialog>
		<el-drawer class="drawer" :destroy-on-close="true" size="70%" :modal="true" :lock-scroll="true"
			v-model="isDrawOnKey" title="一键设置">
			<cpSetByHotMajor @afterSaved="afterSaved" :isShowOptType="isShowOptType"></cpSetByHotMajor>
		</el-drawer>
	</div>
</template>

<script>
	import cpSetByHotMajor from '@/views/hotMajor/cp_SetByHotMajor.vue'
	export default {
		components: {
			cpSetByHotMajor
		},
		data() {
			return {
				// favOnly: false,
				level: '本科',
				activeName: '所有专业',
				size: 3,
				majortree: [],
				majorfavs: [],
				majornothinks: [],
				defaultProps: {
					IP: 'MajorCode',
					children: 'Children',
					label: 'Name',
				},
				keyWord: '',
				dialogVisible: false,
				hotMajors: [],
				activeHot: '',
				expandAll: false,
				wlTypes: [{
					key: '114',
					name: '偏  理'
				}, {
					key: '112',
					name: '偏  文'
				}],
				wlChecked: ['114'],
				isDrawOnKey: false,
				isShowOptType: true
			}
		},
		// computed: {
		// 	showRate() {
		// 		return function(){
		// 			debugger;
		// 			return 0;
		// 		}
		// 	}
		// },
		methods: {
			afterSaved() {
				this.isDrawOnKey = false
				this.init()
			},
			submitHot() {
				this.getmajortree();
			},
			expandHot(v, bForce) {
				this.hotMajors.forEach(item => {
					if (item.ID == v) {
						if (item.childList == undefined || bForce) {
							let temp = 106;
							if (this.level == "本科") {
								temp = 106;
							} else if (this.level == "专科") {
								temp = 107;
							}

							this.$http.get("/StuArchive/GetHotMajorList?groupID=" + v + "&level=" + temp, {

							}, true).then((res) => {
								if (res.code == 1) {
									item.childList = res.data;
								} else {
									this.$message.error(res.msg);
								}
							});
						}
					}
				})
			},
			// onFavOnly() {
			// 	this.favOnly = !this.favOnly;
			// },
			onExpand() {
				this.expandAll = !this.expandAll;
				// 改变每个节点的状态
				this.changeTreeNodeStatus(this.$refs.tree1.store.root);
			},
			// 改变节点的状态
			changeTreeNodeStatus(node) {
				node.expanded = this.expandAll
				for (let i = 0; i < node.childNodes.length; i++) {
					// 改变节点的自身expanded状态
					node.childNodes[i].expanded = this.expandAll
					// 遍历子节点
					if (node.childNodes[i].childNodes.length > 0) {
						this.changeTreeNodeStatus(node.childNodes[i])
					}
				}
			},
			changelevel(val) {
				this.getmajortree();
				this.getmajorfavs();
			},
			handleClick(tab) {
				if (tab.index != 0) {
					this.getmajorfavs();
				}
			},
			changeseq(ID, state) {
				this.$http.get("/StuArchive/ChangeFavSeq?ID=" + ID + "&IsIncrease=" + state, {}, true).then((res) => {
					if (res.code == 1) {
						this.getmajorfavs();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			up(index, row) {
				this.changeseq(row.ID, true);
			},
			down(index, row) {
				this.changeseq(row.ID, false);
			},
			remove(index, row) {
				this.$http.get("/StuArchive/CancleMajorFavs?Key=" + row.Key, {}, true).then((res) => {
					if (res.code == 1) {
						this.majorfavs.filter(x => {
							if (x.Key == row.Key) {
								this.majorfavs.splice(this.majorfavs.indexOf(x), 1);
							}
						});
						this.majornothinks.filter(x => {
							if (x.Key == row.Key) {
								this.majornothinks.splice(this.majornothinks.indexOf(x), 1);
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			handle1(row, column, cellValue, index) {
				if (row.Value == 1) {
					return row.FullName + "⭐️";
				} else if (row.Value == 2) {
					return row.FullName + "⭐⭐️";
				} else if (row.Value == 3) {
					return row.FullName + "⭐⭐⭐";
				} else {
					return row.FullName;
				}
			},
			saveMajorFavs2(data) {
				this.$http.post("/StuArchive/SaveMajorFavs", {
					Key: data.Code,
					Value: data.FavValue,
					RefID: data.MajorID,
				}, true).then((res) => {
					if (res.code == 1) {

					} else {
						this.$message.error(res.msg);
					}
				});
			},
			majorchange(data) {
				this.saveMajorFavs({
					Key: data.MajorCode,
					Value: data.FavValue,
					RefID: data.MajorID,
				}, data);
			},
			majorchange2(data, value) {
				this.$http.post("/StuArchive/SaveMajorFavs", {
					Key: data.Key,
					Value: value,
					RefID: data.RefID,
				}, true).then((res) => {
					if (res.code == 1) {
						this.majorfavs.forEach(x => {
							if (x.RefID == data.RefID) {
								x.Value = value;
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			saveMajorFavs(data, srcMajor) {
				this.$http.post("/StuArchive/SaveMajorFavs", data, true).then((res) => {
					if (res.code == 1) {
						this.updateStatus(srcMajor.Children, srcMajor.FavValue);
						this.synCount(srcMajor)
						this.synParentCount(srcMajor)
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			updateStatus(children, value) {
				if (children == null) return;
				for (var i = 0; i < children.length; i++) {
					children[i].FavValue = value
					this.updateStatus(children[i].Children, value)
				}
			},
			synCount(node) {
				var iFavChildCount = 0;
				if (node.Children != null) {
					node.Children.forEach((item, index) => {
						if (item.Children != null && item.Children.length > 0) {
							iFavChildCount += this.synCount(item)
						} else if (item.FavValue != 0)
							iFavChildCount += 1
					})
					node.FavChildCount = iFavChildCount
					if (node.FavChildCount > 0)
						node.HasFavChild = true
					else
						node.HasFavChild = false
				}
				return iFavChildCount
			},
			synParentCount(node) {
				//debugger
				if (node.parent != null) {
					var iFavChildCount = 0;
					node.parent.Children.forEach((item, index) => {
						if (item.Children != null && item.Children.length > 0) {
							iFavChildCount += item.FavChildCount
						} else if (item.FavValue != 0) {
							iFavChildCount += 1
						}
					})
					node.parent.FavChildCount = iFavChildCount
					if (node.parent.FavChildCount > 0)
						node.parent.HasFavChild = true
					else
						node.parent.HasFavChild = false
					if (node.parent.parent != null)
						this.synParentCount(node.parent)
				}
			},
			getmajorfavs() {
				//获取城市倾向
				this.$http.get("/StuArchive/GetMajorFavs", {
					level: this.level,
					keyWord: this.keyWord
				}, true).then((res) => {
					if (res.code == 1) {
						this.majorfavs = res.data.Favs;
						this.majornothinks = res.data.NoThinks;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getmajortree() {
				//获取城市树
				this.$http.get("/StuArchive/GetMajorTree?Level=" + this.level, {

				}, true).then((res) => {
					if (res.code == 1) {
						this.majortree = res.data;
						var num = 0
						this.majortree.forEach((item, idx) => {
							this.setParent(item)
							num += item.FavChildCount
						})

						if (num == 0 && this.level == "本科") {
							this.isDrawOnKey = true
							this.isShowOptType = false
						} else {
							this.isShowOptType = true
						}

					} else {
						this.$message.error(res.msg);
					}
				});
			},
			setParent(node) {
				if (node.Children != null)
					node.Children.forEach((item, index) => {
						item.parent = node
						this.setParent(item)
					})
			},

			init() {
				this.getmajortree();
				this.getmajorfavs();
			},
			keyUp(e) {
				if (e.keyCode == 13) {
					this.search()
				}
			},
			search() {
				this.$refs.tree1.filter(this.keyWord);
				if (this.activeName != "所有专业")
					this.getmajorfavs();
			},
			filterNode(value, data) {
				if (!value) return true;
				return data.Name.indexOf(value) !== -1;
			},
			getHotMajors() {
				let temp = 106;
				if (this.level == "本科") {
					temp = 106;
				} else if (this.level == "专科") {
					temp = 107;
				}

				this.$http.get("/StuArchive/GetHotMajorGroupList?level=" + temp, {

				}, true).then((res) => {
					if (res.code == 1) {
						this.hotMajors = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			isShowGroup(group) {
				if (!group) return true
				let bIsShow = false
				if (this.wlChecked && this.wlChecked.length > 0) {
					if (this.wlChecked.indexOf('112') >= 0 && group.WCount > 0)
						bIsShow = true
					else if (this.wlChecked.indexOf('114') >= 0 && group.LCount > 0)
						bIsShow = true
				} else
					bIsShow = true
				return bIsShow
			},
			isShowMajor(major) {
				if (!major) return true
				let bIsShow = false
				if (this.wlChecked && this.wlChecked.length > 0) {
					if (this.wlChecked.indexOf('112') >= 0 && major.WLType == '112')
						bIsShow = true
					else if (this.wlChecked.indexOf('114') >= 0 && major.WLType == '114')
						bIsShow = true
				} else
					bIsShow = true
				return bIsShow
			},
			groupChanged(group) {
				this.$http.post("/StuArchive/BatchSetMajorFavs", {
					GroupID: group.ID,
					Value: group.FavValue
				}, true).then((res) => {
					if (res.code == 1) {
						this.expandHot(group.ID, true)
					} else {
						this.$message.error(res.msg);
					}
				});
			}

		},
		created() {
			this.getmajortree();
			this.getHotMajors();
		},
	}
</script>

<style scoped="scoped">
	.el-table__cell:hover .caca {
		display: inline-block;
	}

	.caca {
		display: none;
		color: #409EFF;
		font-weight: 700;
		cursor: pointer;
	}

	.el-tree-node__content:hover .to_jieshao {
		display: inline-block;
	}

	.to_jieshao {
		margin-left: 15px;
		color: #409EFF;
		display: none;
	}

	.seq {
		color: red;
		font-weight: 600;
		border: 1px solid red;
		border-radius: 8px;
		width: 16px;
		height: 16px;
		line-height: 16px;
		display: inline-block;
		text-align: center;
		margin-right: 10px;
	}

	.el-icon-s-release,
	.el-icon-refresh {
		margin-left: 5px;
		margin-right: 10px;
		font-size: 17px;
	}

	.el-icon-s-release:hover,
	.el-icon-refresh:hover {
		color: #F56C6C;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-rate {
		float: left;
		margin-left: 20px;
	}

	.color1 {
		color: #409EFF;
	}

	.color2 {
		color: #E6A23C;
	}

	.color3 {
		color: #F56C6C;
	}

	.color4 {
		color: #67C23A;
	}

	.input-with-select {
		width: 40%;
	}

	.el-checkbox-group {
		margin-bottom: 10px;
	}

	.wltype {
		position: absolute;
		top: 0px;
		right: 0px;
		display: block;
		color: white;
		font-size: 12px;
		padding: 0px 2px;
		height: 16px;
		line-height: 16px;
	}

	* :deep(.el-drawer__header) {
		margin-bottom: 5px;
	}
</style>