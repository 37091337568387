<template>
	<div>
		<el-affix :offset="0">
			<div v-if="log.Name" class="name">
				<el-link type="primary" @click="editName" title="点击修改名称">{{log.Name}}</el-link>
			</div>
			<table class="header-table">
				<tr>
					<td :colspan="colSpan" class="topRow">
						<el-link @click="newLog" type="primary">
							<el-icon class="btn-icon">
								<DocumentAdd />
							</el-icon>
							<span>新建</span>
						</el-link>
						<el-link @click="switchLog" type="primary">
							<el-icon class="btn-icon">
								<FolderOpened />
							</el-icon>
							<span>打开</span>
						</el-link>
						<el-link v-if="isShowSave" type="primary" @click="saveClick">
							<el-icon class="btn-icon">
								<Folder />
							</el-icon>
							<span>
								保存
							</span>
						</el-link>
						<el-link v-else type="primary" @click="saveAsClick">
							<el-icon class="btn-icon">
								<DocumentCopy />
							</el-icon>
							<span>
								另存为
							</span>
						</el-link>
						<el-link type="primary" @click="clear"><el-icon class="btn-icon">
								<Delete />
							</el-icon>
							<span>
								清空
							</span>
						</el-link>
						<el-link v-if="isShowResetSeq" type="primary" @click="resetSeq" title="恢复默认顺序">
							<el-icon class="btn-icon">
								<Ship />
							</el-icon>恢复</el-link>
						<!-- <el-link type="primary" @click="crtRpt" title="根据选择的生成报告">
							<el-icon class="btn-icon">
								<el-icon>
									<Document />
								</el-icon>
							</el-icon>报告</el-link> -->
						<div class="markCount">
							<el-link v-if="isShowGuessLink" type="primary" @click="isShowGuess=true">猜你想要</el-link>
							<span>共包含{{markCount}}条备注</span>
						</div>

					</td>
				</tr>
				<!-- 列头 -->
				<draggable v-model="log.Details" tag="tr" @end="onDragDetailEnd" handle=".mover" filter=".unmover"
					animation="500" ghostClass="ghost" :move="moveHeader">
					<template #item="{element,index}">
						<td v-if="element.Type=='firstTD'" :style="{width:getTdWidth(index)}" class="unmover"
							:key="'header-'+index">
							<el-switch v-model="isHideSame" class="switch" :active-value="1" :inactive-value="0"
								active-text="隐藏相同的" />
							<el-switch v-model="isShowChecked" class="switch" :active-value="1" :inactive-value="0"
								active-text="显示选择的" />
						</td>
						<td v-else-if="element.Type=='plusTD'" :style="{width:getTdWidth(index)}" class="unmover"
							key="plusTD">
							<el-icon class="add" size="40" @click="addClick()">
								<Plus />
							</el-icon>
							<!-- <span class="restNum">可添加{{log.RestNum}}个</span> -->
							<div class="restNum">可添加{{log.RestNum}}个</div>
						</td>
						<td v-else-if="element.Type=='buttonTD'" :style="{width:getTdWidth(index)}" class="unmover"
							key="buttonTD">
							<el-button type="primary" @click="GetCompareResult">比 对</el-button>
						</td>
						<td v-else :class="getTdClass(element)" :style="{width:getTdWidth(index)}" :key="index">
							<el-icon class="remove" @click="del(element)" :key="element.RefID" :id="element.RefID">
								<CircleClose />
							</el-icon>
							<template v-if="log.Compare.IsHasIcon">
								<img :src="element.IconUrl" class="icon">
								<br>
							</template>
							<span :class="element.IconUrl?'text-ellipsis':'mulline-text-ellipsis'"
								:style="{width:(element.Width-25)+'px'}" :title="element.Name">
								{{element.Name}}
							</span>
							<slot name="extend"></slot>
						</td>
					</template>
				</draggable>
			</table>
		</el-affix>
		<table class="data-table">
			<!-- 属性对比 -->
			<draggable v-model="log.Rows" tag="tbody" @end="onDragPropertyEnd" handle=".mover" animation="500"
				ghostClass="ghost" filter=".unmover">
				<template #item="{element,index}">
					<tr v-if="isShowResult && !isHideRow(index) && isShowTr(element)" :key="element.Property.ID">
						<td class="tdLabel mover" :style="{width:getTdWidth(0)}">
							<el-icon v-if="isSortProperty(element.Property)" class="flag">
								<Flag />
							</el-icon>
							{{element.Property.Name}}
							<hSortTag :uniqueFlag="element.Property.ID" :sort='log.SortDirection' class="sortTag"
								:style="{display:(isSortProperty(element.Property)?'block':'none')}"
								@sortChanged="sortChanged" :key="index"></hSortTag>
							<!-- 勾选图标 -->
							<el-icon @click="checkLabelClick(element)" class="check"
								:class="getLabelChecked(element)?'is-checked':''">
								<Check class="exclude" />
							</el-icon>

						</td>
						<td v-for="(cell,i) in element.DataRow" :style="{width:getTdWidth(i+1)}"
							:class="cell.IsBigTxt?'bigTxt':''" @mouseover="onMOver" @mouseout="onMOut">
							<template v-if="isShowTd(cell)">
								<span v-html="cell.HtmlTxt"></span>
								<!-- 备注 -->
								<!-- v-if="cell.Mark.Note" -->
								<el-popover placement="right" title="备注" :width="450"
									:trigger="cell.Mark.Note?'hover':'click'" @show="onShow(cell)"
									v-model:visible="cell.isShowPop">
									<template #reference>
										<el-icon class="mark exclude" :class="cell.Mark.Note?'isHaveMark':''">
											<ChatLineSquare class="exclude" />
										</el-icon>
									</template>
									<div style="overflow-y:auto;max-height: 90vh;padding-right: 20px;">
										<popMark :mark="cell.Mark" @afterDeleted="()=>{afterDelMark(cell)}"
											@afterChanged="(mark)=>{afterChangedMark(cell)}"></popMark>
									</div>
								</el-popover>
								<!-- <el-icon v-else class="mark" @click="addMark(cell)">
									<ChatLineSquare class="exclude" />
								</el-icon> -->
								<!-- 勾选图标 -->
								<el-icon @click="checkClick(element,cell)" class="check"
									:class="cell.Mark.IsChecked?'is-checked':''">
									<Check class="exclude" />
								</el-icon>
							</template>
						</td>
						<template v-for="(item,i) in log.Details">
							<td v-if="i>0 && item.Type" :style="{width:getTdWidth(log.Details.length-1)}"></td>
						</template>

					</tr>
				</template>
			</draggable>
			<!-- </template> -->
		</table>
	</div>
	<el-dialog v-model="isShowQuery" title="添加" width="900px" destroy-on-close>
		<fastAdd :type="log.Type" :areaID="areaID" :stuID="stuID" @afterSelected="afterSelected"></fastAdd>
	</el-dialog>
	<el-dialog v-model="isShowLogs" title="切换" width="900px" destroy-on-close>
		<logList :logID="_logID" :areaID="areaID" @afterSelected="afterSelectedLog"></logList>
	</el-dialog>
	<el-dialog v-model="isShowEditName" title="保存" width="600px" destroy-on-close>
		<span style="margin-right: 10px;">名称</span>
		<el-input v-model="name" style="width: calc(100% - 60px);"></el-input>
		<template #footer>
			<div class="dialog-footer">
				<!-- <span v-if="isShowError" class="error">此名称已存在</span> -->
				<el-button @click="isShowEditName = false">取消</el-button>
				<el-button type="primary" @click="clickOk" :disabled="name && !isShowError?false:true">
					确定
				</el-button>
			</div>
		</template>
	</el-dialog>
	<el-dialog v-model="isShowMark" title="加备注" width="400px" destroy-on-close>
		<addMark :logID="_logID" :detailID="focDetailID" :propertyID="focPropertyID" @afterAdd="afterMarkAdd"
			@afterCancled="afterMarkCancled"></addMark>
	</el-dialog>
	<guess v-if="isShowGuess" :type="type" :stuID="stuID" :areaID="areaID" :fangAnID="fangAnID" :zhiYuanID="zhiYuanID"
		:logID="_logID" @afterAdded="afterGuessAdded" @onClose="onGuessClose">
	</guess>
	<el-drawer v-model="ishowCrtRpt" title="生成报告" size="500" :append-to-body="true">
		<div class="remark">以下是根据勾选的属性项和备注信息生成。</div>
		<div>
			<el-input v-model="rpt" :rows="20" type="textarea" />
		</div>
	</el-drawer>
</template>
<script>
	import fastAdd from './cp_fastAdd.vue'
	import hSortTag from './cp_hSortTag.vue'
	import logList from './cp_logList.vue'
	import addMark from './cp_addMark.vue'
	import popMark from './cp_popMark.vue'
	import api from './api.js'
	import guess from './cp_guess.vue'
	import draggable from 'vuedraggable';
	export default {
		emits: [],
		components: {
			fastAdd,
			hSortTag,
			logList,
			addMark,
			popMark,
			guess,
			draggable
		},
		props: {
			logID: {
				type: Number,
				default: 0
			},
			type: {
				type: Number,
				default: 0
			},
			fangAnID: {
				type: String,
				default: ''
			},
			zhiYuanID: {
				type: String,
				default: ''
			},
			areaID: {
				type: String,
				default: ''
			},
			stuID: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				_logID: '',
				log: {},
				isHideSame: 0,
				isShowChecked: 0,
				isShowQuery: false,
				refLogCount: 0,
				isShowLogs: false,
				name: '',
				isShowEditName: false,
				isChangingName: false,
				isShowMark: false,
				focDetailID: 0,
				focPropertyID: 0,
				focCell: null,
				isShowGuess: false,
				ishowCrtRpt: false,
				isShowError: false
			}
		},
		watch: {
			logID() {
				this._logID = this.logID
				this.GetLogSimple()
				this.GetLogCount()
			},
			name() {
				this.getSameCountOfName()
			}
		},
		computed: {
			isShowResult() {
				let isShow = false
				if (this.log.Rows && this.log.Rows.length > 0)
					isShow = true
				return isShow
			},
			colSpan() {
				let num = 0;
				if (this.log && this.log.Details) {
					num = this.log.Details.length
				}
				return num
			},
			markCount() {
				let num = 0
				if (this.log && this.log.Rows)
					for (var i = 0; i < this.log.Rows.length; i++) {
						let row = this.log.Rows[i].DataRow
						let marks = row.filter(c => c.Mark.Note != null && c.Mark.Note != '')
						if (marks)
							num += marks.length
					}
				return num
			},
			isShowResetSeq() {
				let val = false
				if (this.log.sortPropertyID > 0 || this.log.DetailSeqs || this.log.PropertySeqs)
					val = true
				return val
			},
			isShowGuessLink() {
				let val = false
				if (this.log && this.log.Details) {
					let ary = this.log.Details.filter(c => !c.Type)
					if (ary.length > 0)
						val = true
				}
				return val
			},
			effectDetails() {
				let ary = []
				if (this.log && this.log.Details) {
					ary = this.log.Details.filter(c => !c.Type)
				}
				return ary
			},
			typeName() {
				let val = ''
				switch (this.type) {
					case 1:
						val = '院校'
						break
				}
				return val
			},
			isShowSave() {
				let val = false
				if (!this.log.Name)
					val = true
				return val
			}
		},
		created() {
			this._logID = this.logID
			this.GetLogSimple()
		},
		methods: {
			getTdClass(element) {
				let val = ""
				if (!element.Type)
					val += ' mover'
				if (!element.IconUrl)
					val += ' tdLabel'
				return val
			},
			getTdWidth(i) {
				let val = '0px'
				if (this.log && this.log.Details) {
					let detail = this.log.Details[i]
					if (detail)
						val = detail.Width + 'px'
				}
				return val
			},
			isSortProperty(property) {
				let val = false
				if (this.log && this.log.SortPropertyID == property.ID)
					val = true
				return val
			},
			GetLogSimple() {
				this.$http.get('Compare/GetLogSimple', {
					logID: this._logID,
					type: this.type,
					fangAnID: this.fangAnID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.log = res.data
						this._logID = this.log.ID
						this.GetLogCount()
						this.synStoreDetails(this.log.Details)
						this.fixDetail(this.log.Details)
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			addClick() {
				this.isShowQuery = true
			},
			afterSelected(id, isClose = true) {
				if (isClose)
					this.isShowQuery = false
				this.add(id)
			},
			add(id) {

				let mdl = this.log.Details.find(c => c.RefID == id)
				if (mdl != null) return
				api.addDetail(this._logID, id, this.type, this.areaID, this.fangAnID, this.zhiYuanID, data => {
					this.getData()
					this.isShowGuess = api.isShowGuess(this.type)
				})
			},
			del(detail) {
				api.delDetail(detail.ID, detail.RefID, this.type, this.areaID, this.fangAnID, '', data => {
					this.getData()
				})
			},
			GetCompareResult() {
				this.$http.get('Compare/GetCompareResult', {
					logID: this._logID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.log = res.data
						this.synStoreDetails(this.log.Details)
						this.fixDetail(this.log.Details)
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			fixDetail(details) {
				// debugger
				let cellCount = details.length
				details.splice(0, 0, {
					Type: 'firstTD',
					Width: 150
				})
				if (this.log.RestNum > 0)
					details.splice(details.length, 0, {
						Type: 'plusTD',
						Width: 100,
					})
				if (!this.isShowResult)
					details.splice(details.length, 0, {
						Type: 'buttonTD',
						Width: 100,
					})
				let header = document.getElementsByClassName('header-table')[0]
				let clientWidth = header.clientWidth
				let w = clientWidth
				details.forEach(item => {
					if (item.Type) {
						w -= item.Width
					}
				})
				w -= 20
				let cellWidth = Math.floor(w * 1.0 / cellCount)
				details.forEach(item => {
					if (!item.Type) {
						item.Width = cellWidth
					}
				})
				//末尾精度
				let partWidth = 0
				for (var i = 0; i < details.length - 1; i++)
					partWidth += details[i].Width
				details[details.length - 1].Width = clientWidth - partWidth
			},
			sortChanged(uniqueFlag, sort) {
				if (sort == '') {
					this.log.SortPropertyID = 0
					this.log.SortDirection = sort
				} else {
					this.log.SortPropertyID = uniqueFlag
					this.log.SortDirection = sort
				}

				this.$http.get('Compare/ChangeSort', {
					logID: this._logID,
					sortPropertyID: this.log.SortPropertyID,
					sortDirection: this.log.SortDirection,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.GetCompareResult()
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			isHideRow(idx) {
				let val = true
				if (this.isHideSame) {
					let row = this.log.Rows[idx].DataRow
					for (var i = 0; i < row.length; i++) {
						for (var k = i + 1; k < row.length; k++) {
							if (row[i].Txt != row[k].Txt) {
								val = false
								break
							}
						}
						if (!val)
							break
					}
				} else {
					val = false
				}
				return val
			},
			GetLogCount() {
				this.$http.get('Compare/GetLogCount', {
					logID: this._logID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.refLogCount = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			switchLog() {
				this.isShowLogs = true
			},
			afterSelectedLog(logID) {
				this.switchActiveLog(logID)
				this._logID = logID
				this.getData()
				this.isShowLogs = false
			},
			switchActiveLog(logID) {
				this.$http.get('Compare/switchActiveLog', {
					fromLogID: this._logID,
					toLogID: logID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {} else {
						this.$message.error(res.msg)
					}
				})
			},
			toBak() {
				this.$http.post('Compare/BakLog', {
						logID: this._logID,
						name: this.name,
						areaID: this.areaID
					},
					true).then(res => {
					if (res.code == 1) {
						this.$message.success('另存成功')
						this._logID = res.data
						this.getData()
						this.GetLogCount()
					} else {
						this.$message.error(res.msg)
					}
				})

			},
			saveClick() {
				this.name = ''
				this.name = this.crtLogName()
				this.isChangingName = true
				this.isShowEditName = true
			},
			saveAsClick() {
				this.name = ''
				this.name = this.crtLogName()
				this.isChangingName = false
				this.isShowEditName = true
			},
			clear() {
				this.$confirm("确认清空吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					api.clear(this._logID, this.type, this.areaID, data => {
						this.getData()
					})
				})
			},
			getData() {
				if (this.isShowResult)
					this.GetCompareResult()
				else
					this.GetLogSimple()
			},
			afterMarkSaved() {
				this.isShowMark = false
				this.getData()
				this.$message.success('已添加备注')
			},
			afterMarkAdd(note) {
				this.focCell.Mark.Note = note
				this.isShowMark = false
				this.SaveMark(this.focCell)
				// this.getData()
				// this.$message.success('已添加备注')
			},
			afterMarkCancled() {
				this.isShowMark = false
			},
			addMark(cell) {
				this.focDetailID = cell.DetailID
				this.focPropertyID = cell.ID
				this.focCell = cell
				this.isShowMark = true
			},
			afterDelMark(cell) {
				if (this.focCell)
					this.focCell.isShowPop = false
				this.SaveMark(cell)
				// this.getData()
			},
			afterChangedMark(cell) {
				this.SaveMark(cell)
			},
			SaveMark(cell) {
				this.$http.post('Compare/SaveMark?areaID=' + this.areaID, cell.Mark, false).then(res => {
					if (res.code == 1) {
						this.$message.success("已修改")
						cell.Mark = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			onShow(cell) {
				this.focCell = cell
			},
			// 以下实现鼠标放到标签上，有相同内容时高亮
			onMOver(e) {
				if (e.target == null || e.target.localName == 'td') return
				console.log(e.target.classList)
				if (e.target.classList.contains('exclude'))
					return
				let tr = this.getTrNode(e.target)
				if (tr) {
					let html = e.target.innerHTML
					let elms = tr.getElementsByTagName('*')
					let sames = []
					if (elms)
						for (var i = 0; i < elms.length; i++) {
							let elm = elms[i]
							if (elm.innerHTML == html && html)
								sames.push(elm)
						}
					if (sames.length > 1) {
						let title = '共计' + sames.length + '个相同的'
						sames.forEach(item => {
							item.classList.add('light-same')
							let oldTitle = item.getAttribute('title')
							if (!oldTitle)
								item.setAttribute('title', title)
						})
					}
				}
			},
			onMOut(e) {
				if (e.target == null) return
				let tr = this.getTrNode(e.target)
				if (tr) {
					let elms = tr.getElementsByTagName('*')
					if (elms)
						for (var i = 0; i < elms.length; i++) {
							let elm = elms[i]
							if (elm.classList.contains('light-same'))
								elm.classList.remove('light-same')
						}
				}
			},
			getTrNode(elm) {
				let tr = null
				if (elm == null)
					return tr
				if (elm.localName == "tr") {
					tr = elm
					return tr
				}
				if (elm.parentNode != null) {
					if (elm.parentNode.localName == "tr")
						tr = elm.parentNode
					else
						tr = this.getTrNode(elm.parentNode)
				}
				return tr
			},
			synStoreDetails(details) {
				api.ClearStoreCompareDetails(this.type)
				for (var i = 0; i < details.length; i++) {
					let detail = details[i];
					api.SynStoreCompareDetail(this.type, detail.RefID, true)
				}
			},
			onGuessClose() {
				this.isShowGuess = false
			},
			afterGuessAdded() {
				this.getData()
			},
			onDragPropertyEnd(e) {
				if (e.newDraggableIndex == e.oldDraggableIndex)
					return
				let renderSeqs = this.log.Rows.map((item, index) => {
					return {
						ID: item.Property.ID,
						Seq: index
					}
				});
				this.$http.post('Compare/SavePropertySeqs?logID=' + this._logID + "&areaID=" + this.areaID, renderSeqs,
					false).then(res => {
					if (res.code == 1) {
						this.log.PropertySeqs = res.data
						this.$message.success('已修改')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			onDragDetailEnd(e) {
				if (e.newDraggableIndex == e.oldDraggableIndex)
					return
				let renderSeqs = this.log.Details.filter(c => c.Type == null || c.Type == '').map((item, index) => {
					return {
						ID: item.ID,
						Seq: index
					}
				});
				this.$http.post('Compare/SaveDetailSeqs?logID=' + this._logID + "&areaID=" + this.areaID, renderSeqs,
					false).then(res => {
					if (res.code == 1) {
						this.log.SortPropertyID = 0
						this.log.SortDirection = ''
						this.getData()
						this.$message.success('已修改')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			moveHeader(e) {
				let val = true
				if (this.log.Details[e.relatedContext.index].Type)
					val = false
				return val
			},
			resetSeq() {
				this.$confirm("确认恢复吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.get('Compare/ResetSeq?logID=' + this._logID + "&areaID=" + this.areaID, {}, true)
						.then(res => {
							if (res.code == 1) {
								this.log.SortPropertyID = 0
								this.log.SortDirection = ''
								this.log.PropertySeqs = ''
								this.log.DetailSeqs = ''
								this.getData()
								this.$message.success('已恢复默认顺序')
							} else {
								this.$message.error(res.msg)
							}
						})
				})
			},
			checkClick(row, cell) {
				cell.Mark.IsChecked = 1 - cell.Mark.IsChecked
				this.SaveMark(cell)
			},
			checkLabelClick(row) {
				let isChecked = this.getLabelChecked(row)
				isChecked = 1 - isChecked
				row.DataRow.forEach((item, i) => {
					item.Mark.IsChecked = isChecked
				})
				let marks = row.DataRow.map(c => c.Mark)
				this.$http.post('Compare/BatchSaveMark?areaID=' + this.areaID, marks, true).then(res => {
					if (res.code == 1) {
						marks = res.data
						row.DataRow.forEach((item, i) => {
							let mark = marks.find(c => c.DetailID == item.DetailID)
							if (mark)
								item.Mark = mark
						})
						this.$message.success('已修改')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			getLabelChecked(row) {
				let val = 0
				let ary = row.DataRow.filter(c => c.Mark.IsChecked == 1)
				if (ary.length == row.DataRow.length)
					val = 1
				return val
			},
			isShowTr(row) {
				let val = 1
				if (this.isShowChecked) {
					let ary = row.DataRow.filter(c => c.Mark.IsChecked == 1)
					if (ary.length == 0)
						val = 0
				}
				return val
			},
			isShowTd(cell) {
				let val = 1
				if (this.isShowChecked) {
					if (!cell.Mark.IsChecked)
						val = 0
				}
				return val
			},
			crtRpt() {
				this.ishowCrtRpt = true
			},
			crtLogName() {
				let val = ''
				let num = 0
				for (var i = 0; i < this.effectDetails.length; i++) {
					val += (val == '' ? '' : '、') + this.effectDetails[i].Name
					num++
					if (num >= 2)
						break
				}
				let unit = ''
				if (this.type == 1)
					unit = '所院校'
				else if (this.type == 5)
					unit = '个专业'

				val += '等' + this.effectDetails.length + unit + '的比较'
				return val
			},
			newLog() {
				this.$http.get('Compare/newLog', {
					logID: this._logID,
					type: this.type,
					fangAnID: this.fangAnID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this._logID = res.data
						api.ClearStoreCompareDetails(this.type)
						this.getData()
						this.$message.success('已新建')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			editName() {
				this.name = ''
				this.name = this.log.Name
				this.isChangingName = true
				this.isShowEditName = true
			},
			clickOk() {
				this.isShowEditName = false
				if (this.isChangingName) {
					this.changeName()
				} else {
					this.toBak()
				}
			},
			changeName() {
				this.$http.post('Compare/changeName', {
						logID: this._logID,
						name: this.name,
						areaID: this.areaID
					},
					true).then(res => {
					if (res.code == 1) {
						this.$message.success('保存成功')
						this.getData()
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			getSameCountOfName() {
				if (!this.name)
					return
				this.$http.post('Compare/GetSameCountOfName', {
					logID: this._logID,
					name: this.name,
					areaID: this.areaID
				}, false).then(res => {
					if (res.code == 1) {
						let num = res.data
						if (num > 0)
							this.isShowError = true
						else
							this.isShowError = false
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		}
	}
</script>
<style scoped="scoped" lang="less">
	.header-table {
		margin-top: 0px;
		background-color: white;
	}

	.data-table {
		margin-top: 0px;
	}

	.data-table,
	.data-table tr:first-child,
	.data-table tr:first-child td {
		border-top: 0px;
	}

	.topRow {
		text-align: left;

		>*:nth-child(n+1) {
			margin-right: 15px;
		}
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		text-align: center;
		color: #333;
	}

	table,
	tr,
	td {
		// min-height: 35px;
		padding: 5px 10px;
		border: 1px solid #ddd;
		text-align: center;
		vertical-align: middle;
		position: relative;
		box-sizing: border-box;
	}

	td {
		border-bottom: 0px;
	}

	tr {
		height: 35px;
	}

	tr:hover td {
		border: 1px solid #E6A23C;
	}

	.text-ellipsis {
		// width:calc(100% - 5px);
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mulline-text-ellipsis {
		// width:calc(100% - 5px);
		display: -webkit-box;
		white-space: normal;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		// text-align: left;
	}

	.tdLabel {
		background-color: #f4f4f5;
	}

	.sortTag {
		position: absolute;
		right: 0px;
		top: 0px;
		display: none;
	}

	.tdLabel:hover .sortTag {
		display: block !important;
	}

	.icon {
		width: 70px;
		margin: 10px 0px;
	}

	.add {
		cursor: pointer;
	}

	.remove {
		cursor: pointer;
		display: none;
		position: absolute;
		right: 5px;
		top: 5px;
	}

	td:hover .remove {
		display: block;
		color: red;
	}

	.bigTxt {
		text-align: left;
		vertical-align: top;
	}

	.flag {
		color: red;
		font-size: 16px;
	}

	.mark {
		display: none;
		position: absolute;
		right: 2px;
		top: 2px;
		cursor: pointer;
	}

	.isHaveMark {
		display: block;
		color: #E6A23C;
	}

	td:hover .mark,
	td:hover .check {
		display: block;
	}

	.check {
		position: absolute;
		left: 1px;
		top: 1px;
		font-size: 12px;
		cursor: pointer;
		display: none;
	}

	.is-checked {
		color: #E6A23C;
		display: block;
	}

	.remarks {
		background: white;
		position: fixed;
		right: 0px;
		top: 10px;
		min-height: 500px;
		width: 350px;
	}

	.markCount {
		position: absolute;
		right: 2px;
		top: 5px;
		color: red;
		display: flex;

		>* {
			margin-left: 10px;
		}
	}

	:deep(.light-same) {
		background-color: red;
		color: white;
	}

	.ghost {
		opacity: 0.8;
		background: #c8ebfb;
	}

	.mover {
		cursor: move;
	}

	.btn-icon {
		margin-right: 5px;
	}

	.switch {
		--el-switch-on-color: #13ce66;
		--el-switch-off-color: #b1b3b8;
	}

	.switch:first-child {
		margin-bottom: 10px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.name {
		background-color: white;
		padding: 5px 0px;
	}

	.error {
		font-size: 12px;
		color: red;
		margin-right: 10px;
	}

	.restNum {
		color: red;
		display: block;
	}
</style>